import NullIf from "./NullIf"

class CasinoHelper {
  static getBonusToUse = (casino, forceSports) => {
    const firstDepositBonus = CasinoHelper.GetFirstDepositBonus(casino)
    const sportsbookBonus = CasinoHelper.GetSportsbookBonus(casino)
    const noDepositBonus = CasinoHelper.GetNoDepositBonus(casino)
    const cashbackBonus = CasinoHelper.GetCashbackBonus(casino)

    if (forceSports) return sportsbookBonus

    if (firstDepositBonus) return firstDepositBonus

    if (cashbackBonus) return cashbackBonus
  }

  static HasNonStickyBonus(casino) {
    return casino.bonuses && casino.bonuses.some(x => !x.isSticky)
  }

  static HasExclusiveBonus(casino) {
    return casino.bonuses && casino.bonuses.some(x => x.isExclusive);
  }

  static GetBonusFromCasinoByTypeId(casino, id) {
    if (!casino.bonuses) return null
    let bonuses = casino.bonuses.filter(x => NullIf.isNotNullOrEmpty(x))
    let result = bonuses.filter(x => x.type && x.type._id === id)

    if (result && result[0]) return result[0]
  }

  static GetBonusesFromCasinoByTypeId(casino, id) {
    if (!casino.bonuses) return null

    try {

      let bonuses = casino.bonuses.filter(x => NullIf.isNotNullOrEmpty(x))
      let result = bonuses.filter(x => x.type && x.type._id === id)

      if (result) return result;
    }
    catch(error) {
      //console.log(JSON.stringify(casino.bonuses))
      console.error(error);
      throw error;
    }
  }

  static IsNoDepositBonus(id) {
    return id === "Q3bWwjbIEWuZBDLnkB7cp";
  }

  static NoDepositBonusId = "Q3bWwjbIEWuZBDLnkB7cp";

  static GetNoDepositBonus(casino) {
    let enBonus = this.GetBonusFromCasinoByTypeId(
      casino,
      "Q3bWwjbIEWuZBDLnkB7cp"
    )

    if (NullIf.isNotNullOrEmpty(enBonus)) return enBonus

    return null
  }

  static GetCashbackBonus(casino) {
    let enBonus = this.GetBonusFromCasinoByTypeId(
      casino,
      "1rBGIDOawVhZrUweOt8zHx"
    )
    if (NullIf.isNotNullOrEmpty(enBonus)) return enBonus

    return null
  }

  static IsSportsBookBonus(id) {
    return id === "2KwEEnrylzgdzSGeiOCnaX";
  }

  static GetSportsbookBonus(casino) {
    let enBonus = this.GetBonusFromCasinoByTypeId(
      casino,
      "2KwEEnrylzgdzSGeiOCnaX"
    )

    if (NullIf.isNotNullOrEmpty(enBonus)) return enBonus

    return null
  }

  static GetSixthDepositBonus(casino) {
    let fiBonus = this.GetBonusFromCasinoByTypeId(
      casino,
      "7Ky0wafWr7v1eG5OwafxvY"
    );

    if (NullIf.isNotNullOrEmpty(fiBonus)) return fiBonus

    return null
  }


  static GetFifthDepositBonus(casino) {
    let fiBonus = this.GetBonusFromCasinoByTypeId(
      casino,
      "1kJLbO3Nnlzfdfl5Qg4QnY"
    );

    if (NullIf.isNotNullOrEmpty(fiBonus)) return fiBonus

    return null
  }

  static GetFourthDepositBonus(casino) {
    let fiBonus = this.GetBonusFromCasinoByTypeId(
      casino,
      "CkdZBKNm1WViWFAU7CYrc"
    );

    if (NullIf.isNotNullOrEmpty(fiBonus)) return fiBonus

    return null
  }

  static GetThirdDepositBonus(casino) {
    let fiBonus = this.GetBonusFromCasinoByTypeId(
      casino,
      "3zx8gqy1UneI942eQ4nwAV"
    );

    if (NullIf.isNotNullOrEmpty(fiBonus)) return fiBonus;

    return null
  }

  static GetSecondDepositBonus(casino) {
    let fiBonus = this.GetBonusFromCasinoByTypeId(
      casino,
      "7auZDarnyKrVYX8wQYMh0N"
    );

    if (NullIf.isNotNullOrEmpty(fiBonus)) return fiBonus

    return null
  }

  static GetTopFirstDepositBonus(casino) {
    let fiBonus = this.GetBonusesFromCasinoByTypeId(
      casino,
      "5RxK3fOOaj8nmhbsLyckKE"
    );
    if (NullIf.isNotNullOrEmpty(fiBonus)) {
      if (fiBonus.length === 1)
        return fiBonus[0];

      return fiBonus.sort((x, y) => y.bonusValue - x.bonusValue)[0]
    }

    return null
  }

  static GetFirstDepositBonuses(casino) {
    let bonuses = this.GetBonusesFromCasinoByTypeId(
      casino,
      "5RxK3fOOaj8nmhbsLyckKE"
    );

    return bonuses;
  }

  static GetFirstDepositBonus(casino) {
    let fiBonus = this.GetBonusesFromCasinoByTypeId(
      casino,
      "5RxK3fOOaj8nmhbsLyckKE"
    )

    if (fiBonus && fiBonus.length === 1)
      return fiBonus[0];

    if (fiBonus && fiBonus.length > 1){
      fiBonus.reduce((l, e) => e.bonusValue < l.bonusValue ? e : l);
      return fiBonus[0];
    }

    return null
  }

  static IsFreespinNoDepositBonus(id) {
    return id === this.FreespinNoDepositBonusId;
  }
  static FreespinNoDepositBonusId ="6kr7CAyGwLJohmHX7MCh8b";

  static IsFirstDepositBonus(id) {
    return id === "5RxK3fOOaj8nmhbsLyckKE";
  }

  static GetFreespinNoDepositBonus(casino) {
    let enBonus = this.GetBonusFromCasinoByTypeId(
      casino,
      "6kr7CAyGwLJohmHX7MCh8b"
    )

    if (NullIf.isNotNullOrEmpty(enBonus)) return enBonus

    return null
  }
}

export default CasinoHelper
