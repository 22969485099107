/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useContext} from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import n from "../services/NullIf"
import h from '../services/GenericHelpers'
import t from '../services/GetTranslationValue'
import ch from '../services/casino-bonus-helpers'
import ThemeContext from "../context/ThemeContext"
import { getLocaleFromUrl } from "../services/localize-helpers"
import { getLocaleAlias } from "../graphql-assistant/createLocaleTextGetter"

const getLink = link => {
  if (link[link.length - 1] !== "/") link = link + "/"

  return link
}

const organisationSchema = () => {
  return `{
    "@context":"https://schema.org",
    "@type":"Organization",
    "name":"Mr Gamble",
    "url":"https://mr-gamble.com/",
    "logo":"https://mr-gamble.com/icons/icon-144x144.png",
    "sameAs":["https://twitter.com/Mrgambletwitch",
              "https://www.instagram.com/mrgamble/",
              "https://www.instagram.com/mrgamble/",
              "https://www.twitch.tv/mrgambleslots",
              "https://www.facebook.com/groups/slottikingi",
              "https://www.youtube.com/channel/UCE2nUPUBA3Yp35_0yS-DAmg"]
  }`
}


const gameProviderReviewSchema = (gameProvider) => {

  return JSON.stringify(JSON.parse(`{
    "@context":"https://schema.org/",
    "@type":"Review",
    "datePublished":"${gameProvider.publishDate || '2019-10-16'}",
    "dateModified":"${gameProvider._updatedAt}",
    "itemReviewed":{
      "@type":"Organization",
      "name":"${gameProvider.name}",
      "description":"${gameProvider.seoMeta}"
    },
    "author":{"@type":"Organization",
    "name": "${gameProvider.name}",
    "url": "${gameProvider.url}"},
    "publisher":{"@type":"Organization",
    "name":"Mr Gamble",
    "url": "https://www.mr-gamble.com"
    }
  }`));
}

const reviewSchema = (casino, casinoReview, userRatings) => {
  if (!userRatings || !userRatings.review_count){
    userRatings = { rating_avg: h.casinoAverageRating(casino), review_count: 1 };
  }
  else {
    let totalVotes = parseFloat(userRatings.rating_avg) * parseFloat(userRatings.review_count);
    totalVotes += parseFloat(h.casinoAverageRating(casino));

    let count = parseFloat(userRatings.review_count) + 1;

    userRatings.rating_avg = (totalVotes.toFixed(2) / (userRatings.review_count + 1).toFixed(2)).toFixed(2);
    userRatings.review_count = count;
  }

  return JSON.stringify(JSON.parse(`{
    "@context":"https://schema.org/",
    "@type":"Review",
    "datePublished":"${casinoReview.publishDate || '2019-10-16'}",
    "dateModified":"${casinoReview._updatedAt}",
    "itemReviewed":{
      "@type":"Organization",
      "name":"${casino.name}",
      "description":"${casinoReview.header || casino.name}",
      "sameAs": ["https://www.wikidata.org/wiki/Q770881",
                "${casino.website}"]
    },
    "author":{"@type":"Organization",
    "name": "${casino.name}",
    "url": "${casino.website}"},
    "publisher":{"@type":"Organization",
    "name":"Mr Gamble",
    "url": "https://www.mr-gamble.com"
    }${ userRatings && userRatings.review_count > 0 ? `,
    "reviewRating":{
      "@type":"AggregateRating",
      "worstRating": 0,
      "bestRating": 5,
      "ratingValue":${userRatings && userRatings.rating_avg ? userRatings.rating_avg : 0},
      "reviewCount":${userRatings && userRatings.review_count ? userRatings.review_count : 0}
    }` : ``}
  }`));

}

const GetNamedColumns = (bonuses, column, type) => {
  const { translations} = useContext(ThemeContext);

  return `{
    "csvw:name": "${t.get(translations, 'casino-'+ column +'-schema')}",
    "csvw:datatype": ${type ? `"${type}"` : "string"},
    "csvw:ordered": true,
    "csvw:cells": [
      ${bonuses.map(x => `{
        "csvw:value": "${x[column]}",
        "csvw:primaryKey": "${x[column]}",
        "csvw:ordered": true
    }
  `)}
					]
				}`
}


const GetFunctionalColumn = (bonuses, name, casinoFunction) => {

  const { translations} = useContext(ThemeContext);

  return `{
    "csvw:name": "${t.get(translations, 'casino-'+ name +'-schema')}",
    "csvw:datatype": "string",
    "csvw:ordered": true,
    "csvw:cells": [
      ${bonuses.map(x => `{
        "csvw:value": "${casinoFunction(x)}",
      "csvw:primaryKey": "${casinoFunction(x)}",
    "csvw:ordered": true
}
  `)}
					]
				}`
}

const dataSchema = (translations, locale, bonuses,
                    siteName, siteDescription, location) => {
  if (siteDescription.length < 50)
    siteDescription = siteDescription + ' | www.Mr-Gamble.com | https://mr-gamble.com/ | Mr Gamble'
  return JSON.stringify(JSON.parse(`{
	"@context": "https://schema.org/",
	"@type": "Dataset",
	"name": "${siteName}",
	"description": "${siteDescription || siteName}",
	"url": "https://mr-gamble.com${location && location.pathname}",
	"inLanguage": "${locale}",
	"license": "https://mr-gamble.com/en/about-us/#trademarkscopyrightsandcontentlicense",
	"version": "2019",
	"creator": {
		"@type": "Organization",
		"url": "https://mr-gamble.com/",
		"name": "Mr Gamble"
	},
	"mainEntity": {
		"@type": "csvw:Table",
		"csvw:tableSchema": {
			"csvw:columns": [
				{
					"csvw:name": "${t.get(translations, 'casino-name-schema')}",
					"csvw:datatype": "string",
					"csvw:ordered": true,
					"csvw:cells": [
						${bonuses.map(x => `{
							"csvw:value": "${x.name}",
							"csvw:primaryKey": "${x.name}",
							"csvw:ordered": true
						}
						`)}
					]
				},
				${GetNamedColumns(bonuses, 'dateEstablished', "number")},
				${GetFunctionalColumn(bonuses, 'welcomeBonus', (x) =>{
				    let fdBonus = ch.GetTopFirstDepositBonus(x);

				    if (fdBonus && fdBonus.bonusValue && fdBonus.maximumBonusAmount)
				      return fdBonus.bonusValue + '% / ' + fdBonus.maximumBonusAmount + t.get(translations, 'currency');

            if (fdBonus && fdBonus.bonusValue)
              return fdBonus.bonusValue + '%'

				    return '-';
          }
        )},
        ${GetFunctionalColumn(bonuses, 'freespins', (x) =>{
            let fdBonus = ch.GetFreespinNoDepositBonus(x);
            if (fdBonus)
              return fdBonus.freespinsAmount;

            return '-';
          }
        )},
        ${GetFunctionalColumn(bonuses, 'wageringRequirements', (x) =>{
            let fdBonus = ch.GetTopFirstDepositBonus(x);
            if (fdBonus)
              return fdBonus.wageringRequirement + ' ' + fdBonus.wageringRequirementType;

            return '-';
          }
        )}
			]
		}
	}
}`));
}

const getMaxRating = (casino) => {
  var arr = [casino.bonusRating, casino.usabilityRating, casino.customerServiceRating, casino.gameVarietyRating, casino.transferRating];
  return Math.max(...arr);
}


const getMinRating = (casino) => {
  var arr = [casino.bonusRating, casino.usabilityRating, casino.customerServiceRating, casino.gameVarietyRating, casino.transferRating];
  return Math.min(...arr);
}

const breadCrumbsSchema = (items) => {
  return `
  {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": [ ${items
          .filter(x => x[1] !== "")
          .map((x, index) => `{
          "@type": "ListItem",
          "position": ${index + 1},
          "name": "${x[1]}",
          "item": "https://mr-gamble.com${getLink(x[0])}"
        }`
      )}
    ]
  }`
}

function SEO({ location, description, meta, title, casino, casinoReview, userRatings, gameProvider, homePage, bonuses, alternateSlug, useEnglishGlobal, children}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  let items = []
  n.getBreadcrumbs(location ? location.pathname : "fi").forEach((item, index) =>
    items.push([index > 0 ? items[index - 1][0] + "/" + item : "", item])
  )
  const getCodesForLocale = (l) => {
    if (!l)
      return false;
    if (l === 'enGB')
      return 'en-GB';
    if (l === 'enCA')
      return 'en-CA';
    return l;
  }

  const metaDescription = description || site.siteMetadata.description

  const {locale, translations} = useContext(ThemeContext);
  const lang = getCodesForLocale(locale);

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s`}
    >
      <meta property="og:locale" content={locale} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content={`website`} />
      <meta property="og:image" content={`https://mr-gamble.com/icons/icon-144x144.png`} />
      <meta name="twitter:card" content={'Mr Gamble'} />
      <meta name="twitter:creator" content={site.siteMetadata.author || 'Mr Gamble'} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:title" content={title} />
      <meta property="og:site_name" content={`Mr Gamble`} />
      <meta name="description" content={metaDescription} />
      <meta http-equiv="content-language" content={locale} />
      <meta http-equiv='cache-control' content='no-cache'/>
      <meta http-equiv='expires' content='0'/>
      <meta http-equiv='pragma' content='no-cache'/>
      {children}
      {
        (location && location.pathname) &&
        (location.pathname.includes('/languages/')
          || location.pathname.includes('/licences/')
          || location.pathname.includes('/owner/')
          || getLocaleAlias(locale) === 'uk'
          || getLocaleAlias(locale) === 'ca')
          ? <link rel="canonical" href={`https://mr-gamble.com/en` + location.pathname.replace('/' + getLocaleAlias(locale), '')} />
          : <link rel="canonical" href={`https://mr-gamble.com${location && location.pathname}`} />
      }

      {alternateSlug && alternateSlug
        .filter(x => x !== null)
        .map(x => <link rel="alternate" hrefLang={getCodesForLocale(getLocaleFromUrl(`https://mr-gamble.com/${x}`))}
                                                                             href={`https://mr-gamble.com/${x}`} />)

      }
      {
        alternateSlug && alternateSlug.filter(x => getCodesForLocale(getLocaleFromUrl(`https://mr-gamble.com/${x}`) === 'en')).map(x =>
          <link rel="alternate" hrefLang="x-default"
                href={`https://mr-gamble.com/${x}`} />)
      }
      {/* Schema.org tags */}
      {homePage && <script async defer type="application/ld+json">
        {organisationSchema()}
      </script>
      }

      {/* bonuses should be used as default dataset */}
      { bonuses && <script async defer type="application/ld+json">
        {dataSchema(translations, locale, bonuses, title, metaDescription, location) }
      </script> }
      <script async defer type="application/ld+json">
        {breadCrumbsSchema(items)}
      </script>
      {casino && casinoReview && <script async defer type="application/ld+json">
         {reviewSchema(casino, casinoReview, userRatings)}
      </script>
      }
      {gameProvider && <script async defer type="application/ld+json">
        {gameProviderReviewSchema(gameProvider)}
      </script>
      }

    </Helmet>

  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

export default SEO
